import request from '@/utils/request';

//获取外送客户列表
export function getCustomerDeliver(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/sendlist',
    method: 'post',
    data: data
  });
}

//获取外送项目方id
export function getCustomerDeliverID(data) {
  return request({
    url: '/clientserver.php/Common/agentFlowerNameList',
    method: 'post',
    data: data
  });
}

//外送项目方
export function sendcustomer(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/sendcustomer',
    method: 'post',
    data: data
  });
}

//客户详情
export function Customerinfo(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/sendinfo',
    method: 'post',
    data: data
  });
}

//跟进方式类型
export function followType(data) {
  return request({
    url: '/clientserver.php/Common/followtype',
    method: 'post',
    data: data
  });
}
//取消外送客户列表
export function cancelshowList(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/cancelshow',
    method: 'post',
    data: data
  });
}
//取消外送客户
export function cancelcustomerList(data) {
  return request({
    url: '/clientserver.php/CustomerDeliver/cancelcustomer',
    method: 'post',
    data: data
  });
}