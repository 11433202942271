import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.number.constructor";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import { getCustomerLevel as _getCustomerLevel, agentFlower } from '@/api/ceshi_xiugai/whole';
import { getCustomerDeliver, getCustomerDeliverID as _getCustomerDeliverID, sendcustomer, cancelshowList, cancelcustomerList } from '@/api/ceshi_xiugai2/deliverycustomerinfo';
import MyExportExcel from '@/components/MyExportExcel/index';
export default {
  name: 'Deliverycustomerinfo',
  components: {
    Head: Head,
    MyExportExcel: MyExportExcel
  },
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      menuId: localStorage.getItem('menuId'),
      // 系统教程
      title: '外送客户信息',
      value1: '',
      juris: false,
      jurisBtn: false,
      tableData: [],
      CancelData: [],
      CancelDataId: [],
      CancelDataIdc: [],
      CancelDataIda: [],
      listLoading: true,
      //加载动画
      Cancelding: true,
      StudentLevel: [],
      //学员级别
      Student: '',
      //学员级别所选中的
      agents: [],
      optionsEs: [],
      side: [],
      aaaaEs: false,
      CancelCelivery: false,
      projectSide: [],
      cd_id: '',
      theDillEs: [],
      repeat_listEs: [],
      agent_id: '',
      //项目方名称
      wxnum_mobile_cname: '',
      send_stime: '',
      send_etime: '',
      customer_level: '',
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      tempId: '',
      //选中用的 id
      deliveryItems: false,
      dialogPvVisibleDill: false,
      repeat_listEven: false,
      theDillEs_Even: false,
      NumberEs: 2,
      establish: '0',
      //创建日期排序
      finalFollowUp: '0',
      //最后跟进日期排序
      finalFollow: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.GetList(this.query.page, this.query.pagesize);
      var that = this;
      var jurisdiction = this.$store.getters.Atter;
      if (jurisdiction.indexOf('取消外送展示') != -1) {
        this.juris = true;
      } else {
        this.juris = false;
      }
      if (jurisdiction.indexOf('取消外送') != -1) {
        this.jurisBtn = true;
      } else {
        this.jurisBtn = false;
      }
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.tb.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.getCustomerLevel();
      this.getCustomerDeliverID();
    } else {}
  },
  methods: {
    //获取外送项目方id
    getCustomerDeliverID: function getCustomerDeliverID() {
      var _this2 = this;
      _getCustomerDeliverID({
        is_send: '2'
      }).then(function (res) {
        _this2.agents = res.data;
      });
    },
    getCustomerLevel: function getCustomerLevel() {
      var _this3 = this;
      _getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this3.StudentLevel = res.data;
      });
    },
    handleChange: function handleChange(val) {
      if (val == '') {
        this.customer_level = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else {
        this.query.page = 1;
        this.customer_level = val[1] == undefined ? val[0] : val[1];
        this.GetList(this.query.page, this.query.pagesize);
      }
    },
    deliveryItemsBox: function deliveryItemsBox() {
      var _this4 = this;
      if (this.projectSide == '') {
        this.$message({
          type: 'warning',
          message: '请选择客户'
        });
      } else {
        this.deliveryItems = true;
        agentFlower({
          is_send: '2'
        }).then(function (res) {
          _this4.optionsEs = res.data;
        });
      }
    },
    closeDialog: function closeDialog() {
      this.side = [];
    },
    NewFollowupRecordSure: function NewFollowupRecordSure() {
      var _this5 = this;
      if (this.side == '') {
        this.$message({
          type: 'warning',
          message: '请选择外送的项目方'
        });
      } else {
        this.aaaaEs = true;
        var loading = this.$loading({
          lock: this.aaaaEs,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        sendcustomer({
          customer_data_ids: String(this.cd_id),
          agent_ids: String(this.side)
        }).then(function (res) {
          _this5.aaaaEs = false;
          loading.close();
          if (res.data.knock != '') {
            res.data.knock.forEach(function (item) {
              for (var i in item.list) {
                if (item.list[i] != '') {
                  _this5.theDillEs = res.data.knock;
                }
              }
            });
            _this5.theDillEs_Even = true;
          } else {
            _this5.theDillEs_Even = false;
          }
          if (res.data.repeat == '') {
            _this5.repeat_listEven = false;
          } else {
            _this5.repeat_listEven = true;
            _this5.repeat_listEs = res.data.repeat;
          }
          if (_this5.theDillEs == '' && res.data.repeat == '') {
            _this5.$notify({
              title: '成功',
              message: '外送成功',
              type: 'success',
              duration: 2000
            });
            _this5.deliveryItems = false;
            _this5.GetList(_this5.query.page, _this5.query.pagesize);
          } else {
            _this5.dialogPvVisibleDill = true;
          }
        });
      }
    },
    dialogClose: function dialogClose() {
      this.dialogPvVisibleDill = false;
      this.deliveryItems = false;
      this.aaaaEs = true;
      var loading = this.$loading({
        lock: this.aaaaEs,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.GetList(this.query.page, this.query.pagesize);
      loading.close();
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //外送时间筛选
      if (this.finalFollow == null) {
        this.send_stime = '';
        this.send_etime = '';
        this.GetList(this.query.page, this.query.pagesize);
      } else {
        var sf_time = this.finalFollow[0].toString();
        this.send_stime = sf_time.substring(0, 10);
        var ef_time = this.finalFollow[1].toString();
        this.send_etime = ef_time.substring(0, 10);
        this.query.page = 1;
        this.GetList(this.query.page, this.query.pagesize);
      }
    },
    // 设置滚动条相关信息
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.tb.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    GetList: function GetList(page, pagesize) {
      var _this6 = this;
      this.listLoading = true;
      getCustomerDeliver({
        page: String(page),
        paginate: String(pagesize),
        agent_id: this.agent_id,
        //项目方名称
        wxnum_mobile_cname: this.wxnum_mobile_cname,
        send_stime: this.send_stime,
        send_etime: this.send_etime,
        customer_level: this.customer_level
      }).then(function (respomse) {
        _this6.listLoading = false;
        var str = '';
        _this6.tableData = respomse.data.data.map(function (item) {
          str = String(item.share_agent.join('、'));
          return _objectSpread(_objectSpread({}, item), {
            str: str
          });
        });
        _this6.query.total = respomse.data.total;
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this7 = this;
      this.NumberEs = Number(0);
      if (this.value1 == null) {
        this.start_time = '';
        this.end_time = '';
        this.$nextTick(function () {
          _this7.GetList();
        });
      } else {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this7.GetList();
        });
      }
    },
    //查询
    handleFilter: function handleFilter() {
      if (this.wxnum_mobile_cname == '') {
        this.GetList(this.query.page, this.query.pagesize);
      } else {
        this.query.page = 1;
        this.GetList(this.query.page, this.query.pagesize, this.wxnum_mobile_cname);
      }
    },
    levelsclick: function levelsclick() {
      this.query.page = 1;
      this.GetList(this.query.page, this.query.pagesize);
    },
    selsChange: function selsChange(val) {
      //获取选中id
      this.projectSide = val;
      this.multipleSelection = val;
      var idEs = [];
      var dataId = [];
      for (var i = 0; i < val.length; i++) {
        dataId.push(val[i].cd_id);
        idEs.push(val[i].customer_id);
        this.is_top = val[i].is_top;
        this.userName = val[i].cname;
      }
      this.cd_id = _toConsumableArray(new Set(dataId));
      this.customer_id = _toConsumableArray(new Set(idEs));
      // 单选时打开此代码
      // if (val.length > 1) {
      //     this.$refs.multipleTable.clearSelection();
      //     this.$refs.multipleTable.toggleRowSelection(val.pop());
      // }
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.GetList(this.query.page, val);
      this.query.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.GetList(val, this.query.pagesize);
      this.query.page = val;
    },
    goTodetails: function goTodetails(val) {
      //客户详情
      var routeUrl = this.$router.resolve({
        path: '/DeliverycustomerDetail',
        query: {
          id: val
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      var idEs = [];
      var dataId = [];
      var agent_id = [];
      for (var i = 0; i < val.length; i++) {
        dataId.push(val[i].cd_id);
        idEs.push(val[i].b_cid);
        agent_id.push(val[i].agent_id);
      }
      this.CancelDataIdc = _toConsumableArray(new Set(dataId));
      this.CancelDataId = _toConsumableArray(new Set(idEs));
      this.CancelDataIda = _toConsumableArray(new Set(agent_id));
    },
    CancelDelivery: function CancelDelivery(cdataId) {
      var _this8 = this;
      this.Cancelding = true;
      cancelshowList({
        customer_data_id: String(cdataId)
      }).then(function (res) {
        _this8.CancelData = res.data;
        _this8.Cancelding = false;
        _this8.CancelCelivery = true;
      });
    },
    CancelDeliveryClick: function CancelDeliveryClick() {
      var _this9 = this;
      if (this.CancelDataId != '') {
        this.$confirm('取消外送后项目方将看不到此数据，是否继续？', '提示', {
          type: 'warning'
        }).then(function () {
          cancelcustomerList({
            b_cids: String(_this9.CancelDataId),
            cd_id: String(_this9.CancelDataIdc),
            agent_ids: String(_this9.CancelDataIda)
          }).then(function (res) {
            _this9.$notify({
              title: '成功',
              message: '取消成功',
              type: 'success',
              duration: 2000
            });
            _this9.GetList(_this9.query.page, _this9.query.pagesize);
            _this9.CancelCelivery = false;
          });
        });
      } else {
        this.$notify({
          title: '警告',
          message: '请选择要取消外送的项目方',
          type: 'warning'
        });
      }
    }
  }
};